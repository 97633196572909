.App {
  text-align: center;
  background-color: #ffffff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Dark-toggle {
  font-size: 18pt;
  position: absolute;
  top: 32px;
  left: 32px;
  animation-duration: 2.25s;
  animation-name: animate-in;
  animation-timing-function: cubic-bezier(.5,0,.62,1);
  cursor: pointer;
}

@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css?family=Roboto');

.Name-logo {
  height: 58pt;
  margin-bottom: -8pt;
  animation-duration: 1.55s;
  animation-name: animate-up;
  animation-timing-function: cubic-bezier(.5,-0.01,.6,1);

  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.Calendly-link {
  color: #666666;
  text-decoration: none;
}

.Calendly-link:hover {
  color: black;
}

.Calendly-line {
  color: #666666;
  margin: 1px;
  font-family: "Poppins";
  font-size: 12pt;

  animation-duration: 2s;
  animation-name: animate-in;
  animation-timing-function: cubic-bezier(.5,0,.62,1);
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
}

.App-link {
  color: #61dafb;
}

.Social-flexbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px;
  margin-top: 28px;

  animation-duration: 1.6s;
  animation-name: animate-in;
}

.Social-logo {
  margin: 8px;
  font-size: 26pt;
  transition: transform .04s;
}

.Social-logo:hover {
  transform: scale(1.15);
}

@keyframes animate-in {
  0%, 60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animate-up {
  0%, 50% {
    transform: translateY(65%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* darkModeShit */

.App-dark {
  text-align: center;
  background-color: #181818;
}

.App-header-dark {
  background-color: #181818;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.Calendly-link-dark {
  color: #cdcdcd;
  text-decoration: none;
}

.Calendly-link-dark:hover {
  color: white;
}

.Calendly-line-dark {
  color: #cdcdcd;
  margin: 1px;
  font-family: "Poppins";
  font-size: 12pt;

  animation-duration: 2s;
  animation-name: animate-in;
  animation-timing-function: cubic-bezier(.5,0,.62,1);
}
